import {
  ActionSettings,
  BehaviourSettings,
  ButtonSettings,
  DisplayBy,
  ElementSettings,
  InlineSettings,
  LocationPosition,
  LocationSettings,
  PopupSettings,
  TextAlign,
} from "../components/templates-previews/types/params.interfaces";
import { CAMPAIGNSTEPS } from "../components/campaigns/const/campaigns.enum";
import { Offer } from "./offer.interfaces";
import { LayoutClass } from "../components/campaigns/const/views";
import { Template } from "./templates.interfaces";
import { UseFrom } from "../utility/enums/offer.enums";

export interface CampaignType {
  id?: string;
  name: string;
  description: string;
  isActive?: boolean;
  company_id: string;
  startDate: string;
  endDate: string;
  completionStatus: {
    status: boolean;
    next: CAMPAIGNSTEPS;
  };
  campaignAccessOptions: CampaignAccessOptions;
  sharingOptions: CampaignSharingOptions;
  generalOptions: CampaignGeneralOptions;
  cookieOptions: CampaignCookieOptions;
  allowSharing: boolean;
  templateId: string;
  placementSettings: PlacementSettings[];
  offerCount: number;
  offers: Offer[];
  isOpen?: boolean;
}

export interface CampaignAccessOptions {
  openToEveryone: boolean;
  accessParam: string;
}

export interface FrequncyCapping {
  isEnabled: boolean;
  frequency: number | null;
  reset: number | null;
}

export interface FrequncyCappingOffer {
  useFrom: UseFrom;
  frequency: number;
  reset: number;
}

export interface CampaignSharingOptions {
  allowSharing: boolean;
  shareAdvertiserOffer: boolean;
  shareMessage: string;
  sharingPlatforms: {
    platforms: string[];
  };
}

export interface CampaignDataLayer {
  htmlLocation: string;
  type: string;
  allowSavingDataLayerKeys: boolean;
}

export interface CampaignGeneralOptions {
  automaticallyCheckOptIn: boolean;
  emailCaptureOnAddToOrder: boolean;
  emailCaptureOnOffers: boolean;
  saveOfferOptions: CampaignSaveOfferOptions;
  sendOneEmailPerOffer: boolean;
  showSelectedOffers: boolean;
  showAddToOrder: boolean;
  storeEmailForFuture: boolean;
  useEmailFromDataLayer: boolean;
  htmlSelector: string;
  frequencyCapping: FrequncyCapping;
  dataLayer: CampaignDataLayer;
  allowInReport: boolean;
  randomSlots: boolean;
}

export interface CampaignCookieOptions {
  allowCookies: boolean;
  allowSessionCookies: boolean;
  allowPersistentCookies: boolean;
}

export interface CampaignSaveOfferOptions {
  allowSavingOffers: boolean;
  allowEmail: boolean;
  allowSms: boolean;
}

export interface OrderExtractorProduct {
  htmlElement: string;
  productsSelector: string;
  productNameSelector: string;
  productPriceSelector: string;
  totalPriceSelector: string;
}

export interface OrderExtractor {
  allowExtraction: boolean;
  productExtractorKeys: OrderExtractorProduct;
}

export interface PlacementSettings {
  id: string;
  name?: string;
  isRedeemable: boolean;
  isEnabled: boolean;
  hasExitView: boolean;
  isVisible: boolean;
  displayOnly: boolean;
  promoMessage: string;
  page?: string;
  isEdit: boolean;
  allocation: number;
  templateSettings: LayoutSettings;
}

export interface ButtonShowMore {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  fontWeight: number;
  rounding: number;
  input: string;
}

export interface FiltersSettings {
  filters: boolean;
  textColor: string;
  activeColor: string;
  backgroundColor: string;
  backgroundActiveColor: string;
  borderColor: string;
  borderActiveColor: string;
  showMorePersonalOffers: string;
  showMoreTradeOffers: string;
  personalOffers: string;
  filterOffers: string;
  tradeOffers: string;
  allOffers: string;
}

export interface TemplateOptionSettings {
  location: LocationSettings;
  mainLayout: MainLayoutSettings;
  dealLayout: DealLayoutSettings;
  delay: BehaviourSettings;
  showMore?: ButtonShowMore;
  embeddedElement?: ElementSettings;
  filters?: FiltersSettings;
  otherSettings?: OtherSettingsInterface;
}

export interface MainLayoutSettings {
  fontFamily: string;
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  borderRadius: number;
  align: TextAlign;
  offersNumber: string;
  rowsNumber: string;
  startAsExpanded: boolean;
  highlightColor: string;
  showFeatured: boolean;
  paddingTopBottom: string;
  paddingLeftRight: string;
  headerText: string;
  headerFontSize: string;
  headerFontWeight: number;
  headerTextColor: string;
  subHeaderText: string;
  subHeaderFontSize: string;
  subHeaderFontWeight: number;
  subHeaderTextColor: string;
  countText: string;
  selectText: string;
}

export interface DealLayoutSettings {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  fontSize: string;
  align: TextAlign;
  buttonBackgroundColor: string;
  buttonBorderColor?: string;
  buttonTextColor: string;
  buttonFontSize: string;
  buttonFontWeight: number;
  buttonRounding: number;
  emailOpen: boolean;
  emailMeTheGift: string;
  emailSend: string;
  enterEmail: string;
  emailTextColor: string;
  emailIconColor: string;
  emailButtonBackgroundColor: string;
  emailButtonTextColor: string;
  emailButtonBorderColor?: string;
  emailButtonRounding: number;
}
export interface OtherSettingsInterface {
  showSelectAll: boolean;
  showCheckboxSelect: boolean;
}
export interface TemplateSettings {
  mobile: {
    template?: Template;
    type: LayoutClass;
    button: ButtonSettings;
    settings: TemplateOptionSettings;
    orderExtractor: OrderExtractor;
  };
  desktop: {
    template?: Template;
    type: LayoutClass;
    button: ButtonSettings;
    settings: TemplateOptionSettings;
    orderExtractor: OrderExtractor;
  };
}

export interface LayoutSettings {
  mobile: {
    template?: Template;
    settings?: TemplateOptionSettings;
    type: LayoutClass;
    button: ButtonSettings;
    popup: PopupSettings;
    action: ActionSettings;
    inline: InlineSettings;
    orderExtractor: OrderExtractor;
  };
  desktop: {
    template?: Template;
    settings?: TemplateOptionSettings;
    type: LayoutClass;
    button: ButtonSettings;
    popup: PopupSettings;
    action: ActionSettings;
    inline: InlineSettings;
    orderExtractor: OrderExtractor;
  };
}

export interface TemplateLocationSettings {
  location: LocationPosition;
  transition: number;
  x: number;
  y: number;
}

const locationSettings: TemplateLocationSettings = {
  location: LocationPosition.center,
  transition: 0,
  x: 0,
  y: 0,
};

export const preHeaderText: string = "Unlocked when you buy";

export const preSubHeaderText: string = "Select now, receive after purchase";

export const postHeaderText: string = "Please find your rewards below";

export const postSubHeaderText: string = "Click below to get your reward";

const mainLayout: MainLayoutSettings = {
  fontFamily: "Poppins",
  backgroundColor: "#f9f9f9",
  textColor: "#000000",
  borderColor: "#f9f9f9",
  borderRadius: 12,
  align: TextAlign.center,
  offersNumber: "3",
  rowsNumber: "1",
  paddingTopBottom: "15",
  paddingLeftRight: "5",
  startAsExpanded: true,
  showFeatured: true,
  highlightColor: "#3ab78f",
  headerText: preHeaderText,
  headerFontSize: "18",
  headerFontWeight: 0,
  headerTextColor: "#000000",
  subHeaderText: preSubHeaderText,
  subHeaderFontSize: "16",
  subHeaderFontWeight: 0,
  subHeaderTextColor: "#666666",
  countText: "Free gifts",
  selectText: "Select all",
};

const dealLayout: DealLayoutSettings = {
  backgroundColor: "#ffffff",
  borderColor: "#aaaaaa",
  textColor: "#000000",
  fontSize: "14",
  align: TextAlign.center,
  buttonBackgroundColor: "#aaaaaa",
  buttonBorderColor: "#d8d8d8",
  buttonTextColor: "#f2f2f2",
  buttonFontSize: "14",
  buttonFontWeight: 0,
  buttonRounding: 6,
  emailOpen: false,
  emailMeTheGift: "Email me the gift",
  emailSend: "Send",
  enterEmail: "Enter E-mail",
  emailIconColor: "#000000",
  emailTextColor: "#000000",
  emailButtonBackgroundColor: "#000000",
  emailButtonTextColor: "#ffffff",
  emailButtonBorderColor: "#d8d8d8",
  emailButtonRounding: 6,
};

const behaviourSettings: BehaviourSettings = {
  displayBy: DisplayBy.time,
  afterTime: 0,
};

export const showMoreButtonSettings: ButtonShowMore = {
  backgroundColor: "#383838",
  textColor: "#ffffff",
  borderColor: "#383838",
  rounding: 6,
  fontWeight: 400,
  input: "Show more offers",
};

export const filtersDefaultSettings: FiltersSettings = {
  filters: true,
  showMorePersonalOffers: "Show more personal offers",
  showMoreTradeOffers: "Show more trade offers",
  backgroundColor: "#e5eef5",
  backgroundActiveColor: "#e5eef5",
  textColor: "#939393",
  borderActiveColor: "#939393",
  borderColor: "#939393",
  activeColor: "#0053a0",
  personalOffers: "Personal offers",
  tradeOffers: "Trade offers",
  filterOffers: "Filter offers",
  allOffers: "All",
};

export const elementSettings: ElementSettings = {
  attribute: "",
  existingElement: true,
  elementType: "id",
  fullWidth: true,
  width: 300,
  height: 300,
  marginType: "%",
  margin: 0,
  paddingTop: 0,
  paddingBottom: 0,
  extraElementSelector: "",
  extraElementType: "id",
  extraElementBackground: "#CBEFE8",
  extraElementColor: "#009D7E",
  extraElementText: "You have qualified for free gifts!",
  extraElementTextBold: "Don`t forget to make your selection below",
};

export const otherSettingsDefault: OtherSettingsInterface = {
  showCheckboxSelect: false,
  showSelectAll: false,
};

export const templateDefaultSettings: TemplateOptionSettings = {
  location: locationSettings,
  mainLayout: mainLayout,
  dealLayout: dealLayout,
  delay: behaviourSettings,
  showMore: showMoreButtonSettings,
  embeddedElement: elementSettings,
  filters: filtersDefaultSettings,
  otherSettings: otherSettingsDefault,
};
