import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
  ButtonShapes,
  DisplayBy,
  ImageSizes,
  TextAlign,
} from "../../templates-previews/types/params.interfaces";

export const itemsOffers: ItemType[] = [
  {
    label: "1",
    key: "1",
  },
  {
    label: "2",
    key: "2",
  },
  {
    label: "3",
    key: "3",
  },
];

export const itemsAlign: ItemType[] = [
  {
    label: (
      <span>
        <AlignLeftOutlined /> Left
      </span>
    ),
    key: TextAlign.left,
  },
  {
    label: (
      <span>
        <AlignCenterOutlined /> Center
      </span>
    ),
    key: TextAlign.center,
  },
  {
    label: (
      <span>
        <AlignRightOutlined /> Right
      </span>
    ),
    key: TextAlign.right,
  },
];

export const itemsHeaderIcon: ItemType[] = [
  {
    label: <span>None</span>,
    key: ButtonShapes.square,
  },
  {
    label: (
      <span className="dropdown-icon">
        <svg
          width="47"
          height="47"
          viewBox="0 0 47 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.5 23.5V46H5.5V23.5M23.5 46V12.25M23.5 12.25H13.375C11.8832 12.25 10.4524 11.6574 9.39752 10.6025C8.34263 9.54758 7.75 8.11684 7.75 6.625C7.75 5.13316 8.34263 3.70242 9.39752 2.64752C10.4524 1.59263 11.8832 1 13.375 1C21.25 1 23.5 12.25 23.5 12.25ZM23.5 12.25H33.625C35.1168 12.25 36.5476 11.6574 37.6025 10.6025C38.6574 9.54758 39.25 8.11684 39.25 6.625C39.25 5.13316 38.6574 3.70242 37.6025 2.64752C36.5476 1.59263 35.1168 1 33.625 1C25.75 1 23.5 12.25 23.5 12.25ZM1 12.25H46V23.5H1V12.25Z"
            stroke="#292426"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Gift
      </span>
    ),
    key: ButtonShapes.squareImage,
  },
  {
    label: (
      <span className="dropdown-icon">
        <svg
          width="42"
          height="47"
          viewBox="0 0 42 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
            stroke="#292426"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Lock
      </span>
    ),
    key: ButtonShapes.squareImageLock,
  },
];

export const itemsFontSize: ItemType[] = [
  {
    label: "8",
    key: "8",
  },
  {
    label: "9",
    key: "9",
  },
  {
    label: "10",
    key: "10",
  },
  {
    label: "11",
    key: "11",
  },
  {
    label: "12",
    key: "12",
  },
  {
    label: "13",
    key: "13",
  },
  {
    label: "14",
    key: "14",
  },
  {
    label: "15",
    key: "15",
  },
  {
    label: "16",
    key: "16",
  },
  {
    label: "17",
    key: "17",
  },
  {
    label: "18",
    key: "18",
  },
  {
    label: "19",
    key: "19",
  },
  {
    label: "20",
    key: "20",
  },
  {
    label: "21",
    key: "21",
  },
  {
    label: "22",
    key: "22",
  },
  {
    label: "23",
    key: "23",
  },
  {
    label: "24",
    key: "24",
  },
];

export const itemsRowSize: ItemType[] = [
  {
    label: "1",
    key: "1",
  },
  {
    label: "2",
    key: "2",
  },
];

export const itemsOffersSize: ItemType[] = [
  {
    label: "1",
    key: "1",
  },
  {
    label: "2",
    key: "2",
  },
  {
    label: "3",
    key: "3",
  },
  {
    label: "4",
    key: "4",
  },
];

export const itemsFontSizeEmbedded: ItemType[] = [
  {
    label: "8",
    key: "8",
  },
  {
    label: "9",
    key: "9",
  },
  {
    label: "10",
    key: "10",
  },
  {
    label: "11",
    key: "11",
  },
  {
    label: "12",
    key: "12",
  },
  {
    label: "13",
    key: "13",
  },
  {
    label: "14",
    key: "14",
  },
  {
    label: "15",
    key: "15",
  },
  {
    label: "16",
    key: "16",
  },
  {
    label: "17",
    key: "17",
  },
  {
    label: "18",
    key: "18",
  },
];

export const itemsFontWeight: ItemType[] = [
  {
    label: <span>Regular</span>,
    key: "400",
  },
  {
    label: <span className="semibold">SemiBold</span>,
    key: "600",
  },
  {
    label: <span className="bold">Bold</span>,
    key: "700",
  },
];

export const itemsFontFamily: ItemType[] = [
  {
    label: "Poppins",
    key: "Poppins",
  },
  {
    label: "Open Sans",
    key: "Open Sans",
  },
  {
    label: "Montserrat",
    key: "Montserrat",
  },
  {
    label: "Roboto",
    key: "Roboto",
  },
  {
    label: "Currys",
    key: "Currys",
  },
  {
    label: "Newlook",
    key: "Newlook",
  },
  {
    label: "Screwfix",
    key: "Screwfix",
  },
  {
    label: "GoodHome",
    key: "GoodHome",
  },
];

export const itemsDisplayBy: ItemType[] = [
  {
    label: "Time",
    key: DisplayBy.time,
  },
  {
    label: "Scroll",
    key: DisplayBy.scroll,
    disabled: true,
  },
];

export const itemsSizes: ItemType[] = [
  {
    label: "Small",
    key: ImageSizes.small,
  },
  {
    label: "Medium",
    key: ImageSizes.medium,
  },
  {
    label: "Large",
    key: ImageSizes.large,
  },
];

export const uniqueKey = new Date().getTime();

export const getNumberFromFontWeight = (fontWeight: string) => {
  switch (fontWeight) {
    case "400":
      return "Regular";
    case "600":
      return "SemiBold";
    case "700":
      return "Bold";

    default:
      return "Regular";
  }
};
