import { Switch } from "antd";
import {
  filtersDefaultSettings,
  FiltersSettings,
} from "../../../../../../types/campaign.interfaces";
import { uniqueKey } from "../../../../const/layout-options.enum";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../../hooks/useDebounce.hook";
import { Template } from "../../../../../../types/templates.interfaces";
import { LayoutDevices } from "../../../../const/views";

interface Props {
  updateFiltersSettings: (value: FiltersSettings) => void;
  filtersSettings: FiltersSettings | undefined;
  template: Template | undefined;
}

const FiltersSettingsComponent = ({
  updateFiltersSettings,
  filtersSettings,
  template,
}: Props) => {
  const [showMorePersonalOffers, setShowMorePersonalOffers] = useState<string>(
    filtersSettings?.showMorePersonalOffers || ""
  );
  const debounceShowMorePersonalOffers = useDebounce(
    showMorePersonalOffers,
    1000
  );

  const [filterOffers, setFilterOffers] = useState<string>(
    filtersSettings?.filterOffers || ""
  );
  const debounceFilterOffers = useDebounce(filterOffers, 1000);

  const [showMoreTradeOffers, setShowMoreTradeOffers] = useState<string>(
    filtersSettings?.showMoreTradeOffers || ""
  );
  const debounceShowMoreTradeOffers = useDebounce(showMoreTradeOffers, 1000);

  const [personalOffers, setPersonalOffers] = useState<string>(
    filtersSettings?.personalOffers || ""
  );
  const debouncePersonalOffers = useDebounce(personalOffers, 1000);

  const [tradeOffers, setTradeOffers] = useState<string>(
    filtersSettings?.tradeOffers || ""
  );
  const debounceTradeOffers = useDebounce(tradeOffers, 1000);

  const [allOffers, setAllOffers] = useState<string>(
    filtersSettings?.allOffers || ""
  );
  const debounceAllOffers = useDebounce(allOffers, 1000);

  useEffect(() => {
    setShowMorePersonalOffers(filtersSettings?.showMorePersonalOffers || "");
    setShowMoreTradeOffers(filtersSettings?.showMoreTradeOffers || "");
    setPersonalOffers(filtersSettings?.personalOffers || "");
    setTradeOffers(filtersSettings?.tradeOffers || "");
    setAllOffers(filtersSettings?.allOffers || "");
    setFilterOffers(filtersSettings?.filterOffers || "");
  }, [filtersSettings]);

  const setNewSettings = (
    key: keyof FiltersSettings,
    value: string | number | boolean
  ) => {
    const _filtersSettings: FiltersSettings = filtersSettings
      ? filtersSettings
      : filtersDefaultSettings;

    const newSettings: FiltersSettings = {
      ..._filtersSettings,
      [key]: value,
    };

    updateFiltersSettings(newSettings);
  };

  const handleBackgroundChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    setNewSettings("backgroundColor", color);
  };

  const handleBackgroundActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    setNewSettings("backgroundActiveColor", color);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setNewSettings("textColor", color);
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setNewSettings("activeColor", color);
  };

  const handleBorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setNewSettings("borderColor", color);
  };

  const handleBorderActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    setNewSettings("borderActiveColor", color);
  };

  const handleChangeIsFilters = (value: boolean) => {
    setNewSettings("filters", value);
  };

  useEffect(() => {
    if (typeof debounceShowMorePersonalOffers !== "undefined") {
      setNewSettings("showMorePersonalOffers", debounceShowMorePersonalOffers);
    }
    // eslint-disable-next-line
  }, [debounceShowMorePersonalOffers]);

  useEffect(() => {
    if (typeof debounceShowMoreTradeOffers !== "undefined") {
      setNewSettings("showMoreTradeOffers", debounceShowMoreTradeOffers);
    }
    // eslint-disable-next-line
  }, [debounceShowMoreTradeOffers]);

  useEffect(() => {
    if (typeof debouncePersonalOffers !== "undefined") {
      setNewSettings("personalOffers", debouncePersonalOffers);
    }
    // eslint-disable-next-line
  }, [debouncePersonalOffers]);

  useEffect(() => {
    if (typeof debounceTradeOffers !== "undefined") {
      setNewSettings("tradeOffers", debounceTradeOffers);
    }
    // eslint-disable-next-line
  }, [debounceTradeOffers]);

  useEffect(() => {
    if (typeof debounceAllOffers !== "undefined") {
      setNewSettings("allOffers", debounceAllOffers);
    }
    // eslint-disable-next-line
  }, [debounceAllOffers]);

  useEffect(() => {
    if (typeof debounceFilterOffers !== "undefined") {
      setNewSettings("filterOffers", debounceFilterOffers);
    }
    // eslint-disable-next-line
  }, [debounceFilterOffers]);

  return (
    <>
      <div className="option-box">
        <div className="option-col mbot10">
          <label>
            <span
              className={`mright10 ${
                filtersSettings?.filters ? "font-hover" : "font-disabled"
              }`}
            >
              {filtersSettings?.filters ? "Enabled" : "Disabled"}
            </span>
            <Switch
              onChange={(value: boolean) => handleChangeIsFilters(value)}
              checked={filtersSettings?.filters}
            />
          </label>
        </div>

        {template?.deviceType === LayoutDevices.mobile && (
          <>
            <div className="option-label">Filter offers</div>

            <div className="option-sigle-col mbot10">
              <div className="option-col ">
                <input
                  type="text"
                  className="default-input"
                  value={filterOffers || "Filter offers"}
                  placeholder={"Filter offers"}
                  onChange={(e) => setFilterOffers(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        {template?.deviceType === LayoutDevices.desktop && (
          <>
            <div className="option-label">Filter offers button</div>

            <div className="option-sigle-col mbot10">
              <div className="option-col ">
                <input
                  type="text"
                  className="default-input"
                  value={showMorePersonalOffers || "Show more personal offers"}
                  placeholder={"Show more personal offers"}
                  onChange={(e) => setShowMorePersonalOffers(e.target.value)}
                />
              </div>
            </div>

            <div className="option-label">Trade offers button</div>

            <div className="option-sigle-col mbot10">
              <div className="option-col ">
                <input
                  type="text"
                  className="default-input"
                  value={showMoreTradeOffers || "Show more trade offers"}
                  placeholder={"Show more trade offers"}
                  onChange={(e) => setShowMoreTradeOffers(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        <div className="option-label">Personal offers</div>

        <div className="option-sigle-col mbot10">
          <div className="option-col ">
            <input
              type="text"
              className="default-input"
              value={personalOffers || "Personal offers"}
              placeholder={"Personal offers"}
              onChange={(e) => setPersonalOffers(e.target.value)}
            />
          </div>
        </div>

        <div className="option-label">Trade offers</div>

        <div className="option-sigle-col mbot10">
          <div className="option-col ">
            <input
              type="text"
              className="default-input"
              value={tradeOffers || "Trade offers"}
              placeholder={"Trade offers"}
              onChange={(e) => setTradeOffers(e.target.value)}
            />
          </div>
        </div>

        <div className="option-label">All offers</div>

        <div className="option-sigle-col mbot10">
          <div className="option-col ">
            <input
              type="text"
              className="default-input"
              value={allOffers || "All"}
              placeholder={"All"}
              onChange={(e) => setAllOffers(e.target.value)}
            />
          </div>
        </div>

        <div className="option-two-col">
          <div className="option-col">
            <input
              type="color"
              className="color-box"
              id={`filtersBg${uniqueKey}`}
              value={filtersSettings?.backgroundColor || "#e5eef5"}
              onChange={handleBackgroundChange}
            />
            <label htmlFor={`filtersBg${uniqueKey}`}>
              Background: {filtersSettings?.backgroundColor || "#e5eef5"}
            </label>
          </div>

          <div className="option-col">
            <input
              type="color"
              className="color-box"
              id={`filtersActiveBackground${uniqueKey}`}
              value={filtersSettings?.backgroundActiveColor || "#e5eef5"}
              onChange={handleBackgroundActiveChange}
            />
            <label htmlFor={`filtersActiveBackground${uniqueKey}`}>
              Active background:{" "}
              {filtersSettings?.backgroundActiveColor || "#e5eef5"}
            </label>
          </div>
        </div>

        <div className="option-two-col">
          <div className="option-col">
            <input
              type="color"
              className="color-box"
              id={`showMoreText${uniqueKey}`}
              value={filtersSettings?.textColor || "#939393"}
              onChange={handleTextChange}
            />
            <label htmlFor={`showMoreText${uniqueKey}`}>
              Text: {filtersSettings?.textColor || "#939393"}
            </label>
          </div>
          <div className="option-col">
            <input
              type="color"
              className="color-box"
              id={`activeText${uniqueKey}`}
              value={filtersSettings?.activeColor || "#0053a0"}
              onChange={handleActiveChange}
            />
            <label htmlFor={`activeText${uniqueKey}`}>
              Active text: {filtersSettings?.activeColor || "#0053a0"}
            </label>
          </div>
        </div>

        <div className="option-two-col">
          <div className="option-col">
            <input
              type="color"
              className="color-box"
              id={`filtersBorder${uniqueKey}`}
              value={filtersSettings?.borderColor || "#939393"}
              onChange={handleBorderChange}
            />
            <label htmlFor={`filtersBorder${uniqueKey}`}>
              Border: {filtersSettings?.borderColor || "#939393"}
            </label>
          </div>
          <div className="option-col">
            <input
              type="color"
              className="color-box"
              id={`filtersBorderActive${uniqueKey}`}
              value={filtersSettings?.borderActiveColor || "#939393"}
              onChange={handleBorderActiveChange}
            />
            <label htmlFor={`filtersBorderActive${uniqueKey}`}>
              Active border: {filtersSettings?.borderActiveColor || "#939393"}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltersSettingsComponent;
